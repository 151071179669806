import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Card,
  Checkbox,
  Select,
  Col,
  Row,
  message,
} from "antd";
import Service from "../../service";
import { Link } from "react-router-dom";
import Logowhite from "assets/images/Logowhite.svg";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import { useDispatch } from "react-redux";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const FeedbackForm = (props) => {
  const params_id = props.match?.params?.id || props.computedMatch?.params?.id;
  const [form] = Form.useForm();
  const [empApiData, setEmpApiData] = useState(null);
  const Logo = localStorage.getItem("LogoURL");
  const dispatch = useDispatch();
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [exitNDA, setExitNDA] = useState([]);

  // Function to handle checkbox changes
  const handleCheckBoxChange = (checkedValues) => {
    setCheckBoxValues(checkedValues);
  };

  useEffect(() => {
    getEmployeeById();
  }, []);

  const handleSubmit = async (value) => {
    const checkboxValues = {
      "Dissatisfaction with job": checkBoxValues.includes(
        "Dissatisfaction with job"
      ),
      Relocation: checkBoxValues.includes("Relocation"),
      "Transportation Difficulties": checkBoxValues.includes(
        "Transportation Difficulties"
      ),
      "Better Opportunity": checkBoxValues.includes("Better Opportunity"),
      "Health Condition / Disability": checkBoxValues.includes(
        "Health Condition / Disability"
      ),
      "Home Duties / Family Obligations": checkBoxValues.includes(
        "Home Duties / Family Obligations"
      ),
      "More Pay": checkBoxValues.includes("More Pay"),
      "Career Changes": checkBoxValues.includes("Career Changes"),
    };

    // const feedbackdata = {
    //   checkBox: checkboxValues,
    //   workActivity: value?.workActivity,
    //   challengeJob: value?.challengeJob,
    //   equitableTreatment: value?.equitableTreatment,
    //   responsibilities: value?.responsibilities,
    //   jobResponsibilities: value?.jobResponsibilities,
    //   performanceAppraisal: value?.performanceAppraisal,
    //   operatedEfficiently: value?.operatedEfficiently,
    //   adequatelyStaffed: value?.adequatelyStaffed,
    //   upperManagement: value?.upperManagement,
    //   careerAdvancement: value?.careerAdvancement,
    //   recommendEtpl: value?.recommendEtpl,
    //   employeeComment: value?.employeeComment,
    // };

    const feedbackdata = {
      "What was your reason(s) for leaving company? (Check all items that apply)":
        checkboxValues,
      "I enjoyed my work activities.": value?.workActivity,
      "I was challenged by my job.": value?.challengeJob,
      "My supervisor demonstrated fair & equitable treatment":
        value?.equitableTreatment,
      "My supervisor gave clear instructions for work assignments and responsibilities.":
        value?.responsibilities,
      "My job description accurately reflects my job responsibilities":
        value?.jobResponsibilities,
      "I was given honest, worthwhile performance appraisals":
        value?.performanceAppraisal,
      "My department operated efficiently": value?.operatedEfficiently,
      "My department was adequately staffed.": value?.adequatelyStaffed,
      "Upper management communicates well with employees":
        value?.upperManagement,
      "I felt there were opportunities for career advancement":
        value?.careerAdvancement,
      "I would recommend ETPL as an employer to friends/family":
        value?.recommendEtpl,

      "Employee Comments": value?.employeeComment,
      Credentials: value?.credentials,
    };
    const params = `${params_id}`;

    const formData = new FormData();
    formData.append(`email_token_id`, params);
    formData.append(`feedback_information`, JSON.stringify(feedbackdata));

    for (let i = 0; i < exitNDA.length; i++) {
      formData.append(`exit_nda_upload`, exitNDA[i]);
    }

    // const reqBody = {
    //   email_token_id: params,
    //   feedback_information: feedbackdata,
    //   exit_nda_upload : value?.exit_nda_upload,
    // };
    const response = await Service.makeAPICall({
      methodName: Service.postMethod,
      api_url: Service.postFeedbackData,
      body: formData,
    });

    if (response.data && response.data.status == 200) {
      dispatch(hideAuthLoader());
      message.success(response.data.message);
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      if (response.data) {
        setResponseData(response.data);
        if (response.data.status == 403) {
        } else {
          message.error(response.data.message);
        }
        dispatch(hideAuthLoader());
      }
    }
  };
  const getEmployeeById = async () => {
    try {
      dispatch(showAuthLoader());
      const params = `/${params_id}`;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.feedbackApi + params,
      });

      if (response.data && response.data.data) {
        setEmpApiData(response.data.data);
        dispatch(hideAuthLoader());
      } else {
        if (response.data) {
          setResponseData(response.data);
          if (response.data.status == 403) {
            // console.log(response.data.message);
          } else {
            message.error(response.data.message);
          }
          dispatch(hideAuthLoader());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    form.setFieldsValue({
      terms_and_condition: e.target.checked ? true : null,
    });
  };

  const exportPDF = async () => {
    try {
      dispatch(showAuthLoader());
      // window.open('' + Service.exportemployees + params, "_blank");

      var requestOptions = {
        method: Service.getMethod,
        redirect: "follow",
      };
      const API_URL = Service.API_URL + Service.downloadNDA + "/" + empApiData?._id;
      fetch(API_URL, requestOptions)
        .then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "NDA_for_exit_Formalities_Final.pdf";

            alink.click();
          });
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.log("error", error));
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 13 },
    },
    wrapperCol: {
      xs: { span: 24 },
      md: { span: 16 },
      lg: { span: 11 },
    },
  };
  return (
    <>
      <div className="gx-app-login-wrap account-login">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content feedback-form-div-first">
              <div className="gx-app-logo" style={{ paddingBottom: "80px" }}>
                {Logo ? (
                  <img alt="example" src={Logo} />
                ) : (
                  <img alt="example" src={Logowhite} />
                )}
              </div>
              <div className="gx-app-login-left-content">
                <h6>Welcome to,</h6>
                <h2>Elsner HRMS Portal</h2>
              </div>
            </div>
            {responseData.status !== 403 && (
              <div className="gx-app-login-content feedback-form-div-second">
                <Card style={{ overflowX: "hidden", margin: "0" }}>
                  <div className="inout-header">
                    <h2>Exit Form</h2>
                  </div>
                  <Link to=""> </Link>
                  <Form
                    form={form}
                    className="feedback"
                    noValidate={true}
                    {...formItemLayout}
                    onFinish={handleSubmit}
                  >
                    <Row>
                      <Col md={24} sm={24}>
                        <Form.Item
                          className="top-field"
                          label="Name"
                          name="fname"
                          initialValue={empApiData ? empApiData.full_name : "-"}
                        >
                          <span>{empApiData ? empApiData.full_name : "-"}</span>
                        </Form.Item>
                        <Form.Item
                          label="Date"
                          name="emp_last_working_date"
                          className="search-row top-field"
                        >
                          <span>
                            {empApiData
                              ? moment(
                                empApiData?.emp_last_working_date
                              ).format("DD-MM-YYYY")
                              : "-"}
                          </span>
                        </Form.Item>
                        <Form.Item
                          label="What was your reason(s) for leaving company? (Check all items that apply)"
                          name="checkBox"
                          className="reasons-for-leaving"
                          rules={[
                            {
                              required: true,
                              message: "Please select reason!",
                            },
                          ]}
                        >
                          <Checkbox.Group
                            className="reason-checkbox"
                            onChange={handleCheckBoxChange} // Set the onChange handler
                            value={checkBoxValues} // Set the selected checkbox values
                          >
                            <Checkbox className="exit-nda-reasoncheckbox" value="Dissatisfaction with job">
                              Dissatisfaction with job
                            </Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Relocation">Relocation</Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Transportation Difficulties">
                              Transportation Difficulties
                            </Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Better Opportunity">
                              Better Opportunity
                            </Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Health Condition / Disability">
                              Health Condition / Disability
                            </Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Home Duties / Family Obligations">
                              Home Duties / Family Obligations
                            </Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="More Pay">More Pay</Checkbox>
                            <Checkbox className="exit-nda-reasoncheckbox" value="Career Changes">
                              Career Changes
                            </Checkbox>
                          </Checkbox.Group>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="workActivity"
                          label="I enjoyed my work activities."
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="challengeJob"
                          label="I was challenged by my job."
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="top-field"
                          name="paidFairy"
                          label="I was paid fairly for my work."
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="equitableTreatment"
                          label="My supervisor demonstrated fair & equitable treatment"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="responsibilities"
                          label="My supervisor gave clear instructions for work assignments and responsibilities."
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="jobResponsibilities"
                          label="My job description accurately reflects my job responsibilities"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="performanceAppraisal"
                          label="I was given honest, worthwhile performance appraisals"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="operatedEfficiently"
                          label="My department operated efficiently"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="adequatelyStaffed"
                          label="My department was adequately staffed."
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="upperManagement"
                          label="Upper management communicates well with employees"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          name="careerAdvancement"
                          label="I felt there were opportunities for career advancement"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="top-field"
                          name="recommendEtpl"
                          label="I would recommend ETPL as an employer to friends/family"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select defaultValue="--Select--">
                            <Select.Option value="Strongly Agree">
                              Strongly Agree
                            </Select.Option>
                            <Select.Option value="Agree">Agree</Select.Option>
                            <Select.Option value="Neither Agree Nor Disagree">
                              Neither Agree Nor Disagree
                            </Select.Option>
                            <Select.Option value="Disagree">
                              Disagree
                            </Select.Option>
                            <Select.Option value="Strongly Disagree">
                              Strongly Disagree
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Please download exit NDA document for signature "
                          name="exitndadownload"
                          className="top-field"
                        >
                          <Button onClick={exportPDF} type="primary">
                            Exit NDA
                          </Button>
                          <></>
                        </Form.Item>
                        <Form.Item
                          label="Please upload your signed NDA Document!"
                          name="exit_nda_upload"
                          className="top-field"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please upload your signed NDA Document!",
                            },
                          ]}
                        >
                          <Input
                            type="file"
                            accept="application/pdf"
                            icon={<UploadOutlined />}
                            onChange={(e) => {
                              setExitNDA(e.target.files);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Employee Comments"
                          name="employeeComment"
                          className="top-field"
                        >
                          <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                          label="Credentials"
                          name="credentials"
                          className="top-field"
                          rules={[
                            {
                              required: true,
                              message: "Please enter credentials!",
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
  label=" "
  name="terms_and_condition"
  className="top-field remove-star"
  valuePropName="checked" // Link the form value directly to the checkbox's checked state
  rules={[
    {
      required: true,
      message: "Please agree to the terms and conditions!",
    },
  ]}
>
  <Checkbox onChange={handleCheckboxChange} style={{ width: "100%" }}>
    I accept terms and conditions
  </Checkbox>
</Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <Form.Item
                          name="terms_and_condition"
                          // className="top-field"
                        > */}
                          {/* <Checkbox.Group
                            className="reason-checkbox"
                            // Set the onChange handler
                            value={checkBoxValues} // Set the selected checkbox values
                          > */}
                           
                          {/* </Checkbox.Group> */}
                        {/* </Form.Item> */}
                      </Col>
                    </Row>

                    <Form.Item className="ant-col-xs-24 ant-col-sm-24 ant-col-lg-10 leavebtns">
                      <div className="add-leave-button ">
                        <Button id="addbutton" type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            )}
            {responseData.status === 403 && (
              <div className="gx-app-login-content response-msg">
                <h2>{responseData.message}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedbackForm;
